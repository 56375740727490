<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-row>
        <el-col :span="24" style="margin-bottom:20px;">
          <el-button type="primary" icon="el-icon-plus" @click="addRoot">新增根节点</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addSub">新增子节点</el-button>
          <el-button type="danger" icon="el-icon-delete" style="background:#FF5722" @click="del">删除</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-tree ref="tree" :data="listdata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-col>
        <el-col :span="13" class="fromRight">
          <div v-html="editTitle" >当前信息</div>
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="编码" prop="code" :label-width="formLabelWidth">
              <el-input v-model='form.code' @input="e => form.code= validForbid(e)" show-word-limit maxlength="20"  style="padding:0 48px 0 0" ></el-input>
            </el-form-item>
            <el-form-item label="节点类型" prop="trainCode" :label-width="formLabelWidth">
              <el-select v-model="form.trainCode" :disabled="editStatus" style="padding:0 48px 0 0">
                <el-option v-for="(item,index) in trainTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中文名称" prop="nameCh" :label-width="formLabelWidth">
              <el-input v-model="form.nameCh"  @input="e => form.nameCh= validForbid(e)" show-word-limit maxlength="50"  style="padding:0 48px 0 0" ></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="nameEn" :label-width="formLabelWidth">
              <el-input v-model="form.nameEn"  @input="e => form.nameEn= validForbid(e)" show-word-limit maxlength="50"  style="padding:0 48px 0 0"  ></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="image" :label-width="formLabelWidth">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :headers="importHeader"
                :on-success="handlesuccess"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                :limit="1"
                multiple
                :file-list="fileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                list-type="picture"
              >
                <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
              </el-upload>
              <!-- <img :src="imgurl" alt="" style="width:80px;"> -->
            </el-form-item>
            <el-form-item label="说明" prop="remark" :label-width="formLabelWidth">
              <el-input  show-word-limit maxlength="50"  style="padding:0 48px 0 0"  v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
              <el-input type="number"  :min="1" :max="9999"  @input="e => form.sort=parserNumber(e,1,9999)" style="padding:0 48px 0 0" v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item class="butArea">
              <el-button type="primary" @click="save('form')">保存</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { sysServerUrl } from '@/assets/js/common.js'
import { trainData, typeData, currentInfo, trainAdd, trainUpdate,trainDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'nameCh'
      },
      editTitle: '当前信息',
      action: 0,
      editStatus:true,
      sltNodeId: '',
      sltNodeType: '',
      sltNodeName: '',
      uploadUrl: '',
      form: {
        id: '',
        pid: '',
        code: '',
        trainCode: '',
        nameCh: '',
        nameEn: '',
        image: '',
        imgName: '',
        remark: '',
        sort: 10
      },
      listdata: [],
      trainTypeList: [],
      formLabelWidth: '100px',
      fileList: [],
      rules: {
        code: [{ required: true, message: '编码不能为空', trigger: 'blur' }],
        trainCode: [{ required: true, message: '节点类型不能为空', trigger: 'blur' }],
        nameCh: [{ required: true, message: '中文名称不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  methods: {
    dataList () {
      trainData().then(res => {
        this.listdata = res.data.data
        this.editStatus=true
      })
    },
    getTrainTypeList () {
      typeData().then(res =>{
        if (res.data.code === 100) {
          this.trainTypeList = res.data.data
        }
      })
    },
    addRoot(){
      this.action=1 
      this.editTitle='添加主机厂根节点'
      this.editStatus=true
      this.fileList = []
      this.form.trainCode='firm'
      this.form.pid='0'
      this.form.id = ''
      this.form.code = ''
      this.form.nameCh = ''
      this.form.nameEn = ''
      this.form.image = ''
      this.form.remark = ''
      this.form.sort = 10
      this.sltNodeId=''
      this.sltNodeType=''
      this.sltNodeName =''
    },
    addSub(){
      if(this.sltNodeId==''){
         this.$message.warning('请先选中父级节点')
         return false
      }else{
        if(this.sltNodeType=='train'){
           this.$message.warning('选中的父级节点不能为车系节点')
            return false
        }else if(this.sltNodeType=='firm'){
            this.form.trainCode = 'brand'
            this.editStatus=true
        }else if(this.sltNodeType=='brand'){
            this.form.trainCode = 'train'
            this.editStatus=true
        }
      }
      this.action=1 
      this.editTitle='当前选中父级节点：'+this.sltNodeName
      this.fileList = []

      this.form.pid=this.sltNodeId
      this.form.id = ''
      this.form.code = ''
    
      this.form.nameCh = ''
      this.form.nameEn = ''
      this.form.image = ''
      this.form.remark = ''
      this.form.sort = 10

      this.sltNodeId=''
      this.sltNodeType=''
      this.sltNodeName =''
    },
    del(){
       var _this=this
      if(this.sltNodeId==''){  
        this.$message.warning('请先选中要删除的节点')
        return false
      }else{
        _this.$confirm('确定删除 ' +  this.sltNodeName + '?', '删除目录节点', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //删除节点
          _this.deleteNode(this.sltNodeId) 
        }).catch((error)=>{
          _this.$message.error('删除失败')
        })
      }
    },
    // 显示详情
    handleNodeClick (data) {
      this.fileList = []
      var params = '?id=' + data.id
       this.editTitle='当前信息'
      currentInfo(params).then(res => {
        let ret= res.data
        if(ret!=null){
          this.sltNodeId=ret.data.id
          this.sltNodeType= ret.data.train_code
          this.sltNodeName =ret.data.name_ch
          this.editStatus=true
          this.form.id = ret.data.id
          this.form.pid= ret.data.pid
          this.form.code = ret.data.code
          this.form.trainCode = ret.data.train_code
          this.form.nameCh = ret.data.name_ch
          this.form.nameEn = ret.data.name_en
          this.form.image = ret.data.image
          this.form.remark = ret.data.remark
          this.form.sort = ret.data.sort
          //var clickName = { name: this.form.image, url: sysServerUrl + 'sys/upload/display?filePath=' + this.form.image }
          if(this.form.image !=null &&this.form.image.length>0){
            // var imgName = (this.form.image).split('/')[2]
            var clickName = { url: sysServerUrl + 'sys/upload/display?filePath=' + this.form.image }
            this.fileList.push(clickName)
          }
        }
      })
    },
    handlesuccess (file, fileList) {
      this.form.image = file.data.fileUrl
      this.fileList = []
      if(this.form.image != null && this.form.image.length > 0){
        // var imgName = (this.temp.icon).split('/')[2]
        var clickName = { url: sysServerUrl + 'sys/upload/display?filePath=' + this.form.image }
        this.fileList.push(clickName)
      }
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message.warning('上传模板只能是 png、jpg、jpeg、gif格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传模板大小不能超过 5MB!')
      }
      // return isLt2M
    },
    handleRemove (file, fileList) {
      if(fileList.length == '0'){
        this.fileList = []
        this.form.image = ''
      }
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除当前图片？`)
    },
    save(form){
       var _this=this
        this.$refs[form].validate((valid) => {
        if (valid) {
               var params = new URLSearchParams()
                  params.append('id', this.form.id)
                  params.append('pid', this.form.pid)
                  params.append('code',this.form.code)
                  params.append('trainType', 'trainType')
                  params.append('trainCode', this.form.trainCode)
                  params.append('nameCh', this.form.nameCh)
                  params.append('nameEn', this.form.nameEn)
                  params.append('image', this.form.image)
                  params.append('remark', this.form.remark)
                  params.append('sort', this.form.sort)
                  if(_this.action==1){
                    _this.action=0
                  }
                  if(this.form.id!=null && this.form.id!='' && this.form.id!='null'){
                    _this.updateNode(params)
                  }
                  else{
                    _this.addNode(params)
                  }
                  
        } else {
          _this.$message.error('请完善表单信息')
           
        }

     })
    },
    addNode(params){
       var _this=this
      trainAdd(params).then(res => {
        if(res.data.code==100){
          _this.$message.success('添加节点成功')
          _this.dataList()
        }else{
           _this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        _this.$message.error('添加节点失败')
      })
    },
    // 修改
    updateNode (params) {
        var _this=this
      trainUpdate(params).then(res => {
        if(res.data.code==100){
          _this.$message.success('保存节点信息成功')
          _this.dataList()
        }else{
           _this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        _this.$message.error('保存节点信息失败')
      })
    },
    deleteNode(nodeId){
        var _this=this
      var params = new URLSearchParams()
      params.append('id', nodeId)
      trainDel(params).then(res => {
        if(res.data.code==100){
          _this.$message.success('删除节点成功')
          _this.dataList()
        }else{
          _this.$message.error(res.data.msg)
        }
      }).catch(function(error){
        _this.$message.error('删除节点失败,出现异常')
      })
    },
    // 重置
    resetForm () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=carTrain'
    this.dataList()
    this.getTrainTypeList()
  }
}
</script>
<style scoped>
  .fromRight{
    border:1px solid #eee;
  }
  .fromRight>div{
    background: #fafafa;
    font-weight: bold;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
    border-bottom:1px solid #eee;
    box-sizing: border-box;
  }
  .fromRight .el-form-item{
    padding: 10px 15px 10px 0;
    border-bottom:1px solid #eee;
    margin-bottom:0px !important;
    box-sizing: border-box;
  }
</style>
<style>
  .fromRight .butArea .el-form-item__content{
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
    margin: 0 auto !important
  }
  .fromRight .el-select{
    width: 100%
  }
  /* .fromRight .el-upload-list__item-name{
    width: 150px;
  } */
  /* .el-upload-list{
    width:90%;
    overflow: hidden;
  }
  .el-upload-list__item-name{
    width: 60%
  }
  .el-upload-list--picture .el-upload-list__item-thumbnail{
    width: auto;
    height: 70px;
  } */
  
/* .el-upload-list__item {
  transition: none !important;
} */
</style>
